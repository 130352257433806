<template>
    <div>
        <div class="h-handle-bg">
            <div class="h-handle-button">
                <p class="h-return"><el-button type="text" size="small" @click="$parent.checkSaveStatus">返回</el-button> </p>
                <div class="h-b">
					<el-button type="primary" size="small" @click="add" v-right-code="'Sysexternalsystem:Edit'">新增</el-button>
				</div>
                <div class="h-b"><el-button type="primary" size="small" @click="saveExternalSystem" v-right-code="'Sysexternalsystem:Edit'">保存</el-button> </div>
            </div>
        </div>
        <el-tabs type="border-card" v-model="activeName" @tab-click="tab1Click">
            <el-tab-pane name="tabbasic">
                <span slot="label"><i class="el-icon-search"></i> 基本信息</span>
                <div class="form-list">
                    <el-form :model="dataSource" ref="_externalSystemForm" :rules="externalSystemCheckRule">
                        <el-form-item>
                            <el-col :span="3" class="form-title"><span style="color:red;">*</span>系统标识：</el-col>
                            <el-col :span="7">
                                <el-form-item>
                                    <el-input v-model="dataSource.ExternalIdentity" :disabled="!isDisabled" placeholder="系统标识"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="3" class="form-title"><span style="color:red;">*</span>系统名称：</el-col>
                            <el-col :span="7">
                                <el-form-item prop="ExternalSystemName">
                                    <el-input v-model="dataSource.ExternalSystemName" :maxlength="200" :minlength="1" placeholder="系统名称"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                        <el-form-item>
                            <el-col :span="3" class="form-title"><span style="color:red;">*</span>外部系统类型：</el-col>
                            <el-col :span="7">
                                <el-form-item prop="ExternalSystemType">
                                    <el-select v-model="dataSource.ExternalSystemType" :maxlength="20">
                                        <el-option v-for="item in ExternalSystemTypes"
                                                   :key="item.value"
                                                   :label="item.label"
                                                   :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                        <el-form-item>
                            <el-col :span="3" class="form-title">
                                系统描述：
                            </el-col>
                            <el-col :span="17">
                                <el-form-item prop="ExternalSystemDesc">
                                    <el-input type="textarea" v-model="dataSource.ExternalSystemDesc" :maxlength="2000"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                    </el-form>
                </div>
            </el-tab-pane>
        </el-tabs>

        <el-tabs type="border-card"  v-model="tabActiveName" @tab-click="tab2Click">
            <el-tab-pane name="tabcomparam">
                <span slot="label"><i class="el-icon-date"></i> 公共参数</span>
                <div class="form-padding">
                    <div class="h-handle-button">
                        <div class="h-b">
                            <el-button type="primary" size="small" @click="addComParam">添加公共参数</el-button>
                        </div>
                    </div>
                    <el-table :data="dataSource.ComExternalParamList" border highlight-current-row>
                        <el-table-column prop="Name" label="参数名称">
                            <template slot-scope="scope">                                
                                <el-input v-if="scope.row.RowEdit" v-model="scope.row.Name" size="mini" placeholder="参数名称" />
                                <span v-else>{{scope.row.Name}}</span>
                            </template>
                        </el-table-column>

                        <el-table-column prop="Code" label="参数编码">
                            <template slot-scope="scope">
                                <el-input v-if="scope.row.RowEdit" v-model="scope.row.Code" size="mini" placeholder="参数编码" />
                                <span v-else>{{scope.row.Code}}</span>
                            </template>
                        </el-table-column>

                        <el-table-column prop="Value" label="参数值">
                            <template slot-scope="scope">
                                <el-input v-if="scope.row.RowEdit" v-model="scope.row.Value" size="mini" placeholder="参数值" />
                                <span v-else>{{scope.row.Value}}</span>
                            </template>
                        </el-table-column>

                        <el-table-column fixed="right" label="操作" width="120">
                            <template slot-scope="scope">
                                <el-button v-show="scope.row.RowEdit" @click="saveParam(scope.row)" size="small" type="text">保存</el-button>
                                <el-button v-show="!scope.row.RowEdit" @click="editParam(scope.row)" size="small" type="text">编辑</el-button>
                                <el-button @click="removeComParam(scope.row)" size="small" type="text">移除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>                   
            </el-tab-pane>

            <el-tab-pane name="tabcommap">
                <span slot="label"><i class="el-icon-date"></i> 公共映射</span>
                <div v-if="tabActiveName === 'tabcommap'" class="form-padding">
                    <div class="h-handle-button">
                        <div class="h-b">
                            <el-button type="primary" size="small" @click="addComMap">添加公共映射</el-button>
                        </div>
                    </div>
                    <el-table :data="dataSource.ComExternalMappingList" border highlight-current-row>
                        <el-table-column prop="Name" label="映射名称">
                            <template slot-scope="scope">
                                <el-input  v-if="scope.row.RowEdit" v-model="scope.row.Name" size="mini" placeholder="映射名称" />
                                <span v-else>{{scope.row.Name}}</span>
                            </template>
                        </el-table-column>

                        <el-table-column prop="Code" label="映射编码">
                            <template slot-scope="scope">
                                <el-input v-if="scope.row.RowEdit" v-model="scope.row.Code" size="mini" placeholder="映射编码" />
                                <span v-else>{{scope.row.Code}}</span>
                            </template>
                        </el-table-column>

                        <el-table-column prop="ExternalValue" label="外部系统值">
                            <template slot-scope="scope">
                                <el-input v-if="scope.row.RowEdit" v-model="scope.row.ExternalValue" size="mini" placeholder="外部系统值" />
                                <span v-else>{{scope.row.ExternalValue}}</span>
                            </template>
                        </el-table-column>

                        <el-table-column prop="LocalValue" label="本系统值">
                            <template slot-scope="scope">
                                <el-input v-if="scope.row.RowEdit" v-model="scope.row.LocalValue" size="mini" placeholder="本系统值" />
                                <span v-else>{{scope.row.LocalValue}}</span>
                            </template>
                        </el-table-column>

                        <el-table-column fixed="right" label="操作" width="70">
                            <template slot-scope="scope">
                                <el-button v-show="scope.row.RowEdit" @click="saveParam(scope.row)" size="small" type="text">保存</el-button>
                                <el-button v-show="!scope.row.RowEdit" @click="editParam(scope.row)" size="small" type="text">编辑</el-button>
                                <el-button @click="removeComMap(scope.row)" size="small" type="text">移除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-tab-pane>

            <el-tab-pane name="tabparam">
                <span slot="label"><i class="el-icon-date"></i>系统接入参数</span>
                <div v-if="tabActiveName === 'tabparam'" class="form-padding">
                    <div class="h-handle-button">
                        <div class="h-b">
                            <el-button type="primary" size="small" @click="addParam">添加参数</el-button>
                        </div>
                    </div>
                    <el-table :data="dataSource.ExternalParamList" border highlight-current-row >
                        <el-table-column prop="Name" label="参数名称">
                            <template slot-scope="scope">
                                <el-input v-if="scope.row.RowEdit" v-model="scope.row.Name" size="mini" placeholder="参数名称" />
                                <span v-else>{{scope.row.Name}}</span>
                            </template>
                        </el-table-column>

                        <el-table-column prop="Value" label="参数值">
                            <template slot-scope="scope">
                                <el-input v-if="scope.row.RowEdit" v-model="scope.row.Value" size="mini" placeholder="参数值" />
                                <span v-else>{{scope.row.Value}}</span>
                            </template>
                        </el-table-column>

                        <el-table-column fixed="right" label="操作" width="120">
                            <template slot-scope="scope">
                                <el-button v-show="scope.row.RowEdit" @click="saveParam(scope.row)" size="small" type="text">保存</el-button>
                                <el-button v-show="!scope.row.RowEdit" @click="editParam(scope.row)" size="small" type="text">编辑</el-button>
                                <el-button @click="removeParam(scope.row)" size="small" type="text">移除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-tab-pane>

            <el-tab-pane name="tabmap">
                <span slot="label"><i class="el-icon-date"></i>系统接入映射</span>
                <div v-if="tabActiveName === 'tabmap'" class="form-padding">
                    <div class="h-handle-button">
                        <div class="h-b">
                            <el-button type="primary" size="small" @click="addMap">添加映射</el-button>
                        </div>
                    </div>
                    <el-table :data="dataSource.ExternalMappingList"  border highlight-current-row>
                        <el-table-column prop="Name" label="映射键">
                            <template slot-scope="scope">
                                <el-input v-if="scope.row.RowEdit" v-model="scope.row.Name" size="mini" placeholder="参数名称" />
                                <span v-else>{{scope.row.Name}}</span>
                            </template>
                        </el-table-column>

                        <el-table-column prop="Value" label="映射值">
                            <template slot-scope="scope">
                                <el-input v-if="scope.row.RowEdit" v-model="scope.row.Value" size="mini" placeholder="参数值" />
                                <span v-else>{{scope.row.Value}}</span>
                            </template>
                        </el-table-column>

                        <el-table-column fixed="right" label="操作" width="120">
                            <template slot-scope="scope">
                                <el-button v-show="scope.row.RowEdit" @click="saveParam(scope.row)" size="small" type="text">保存</el-button>
                                <el-button v-show="!scope.row.RowEdit" @click="editParam(scope.row)" size="small" type="text">编辑</el-button>
                                <el-button  @click="removeMap(scope.row)" size="small" type="text">移除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
  
</template>
<script>
    export default {
        data() {
            var checkExternalSystemName = function (rule, value, callback) {
                if (!value) return callback(new Error('请输入系统名称'));
                callback();
            };
            return {
                activeName: 'tabbasic',
                tabActiveName:'tabcomparam',
                ExternalSystemTypes: [
                    { label: '下单', value: '100' },
                    { label: '仓储', value: '200' },
                    { label: '配送', value: '300' },
                    { label: '其他', value: '400' },

                ],
                IsSystemDefaults:[
                    { label: '是', value: true },
                    { label: '否', value: false }
                ],
                multipleSelection: [],
                externalSystemCheckRule: {
                    ExternalSystemName: [{ validator: checkExternalSystemName, trigger: 'blur' }],
                },
                isDisabled:false
            }
        },
        props: {
            config: {
                isDetailDisplay: false
            },
            dataSource: {
                //ComExternalParamList: []
            },
        },
        watch: {
			dataSource: {
				handler(curVal, oldVal) {
					this.isDisabled = this.dataSource.ExternalSystemID==null||this.dataSource.ExternalSystemID==0;
				},
				deep: true
			}
		},
        mounted() {
            this.Event.$on("clearEditExternalSystemForm", () => this.resetForm);
        },

        methods: {
            add(){
                this.Event.$emit("onAdd");
            },
            tab1Click: function (tab, event) {
            },
            tab2Click: function (tab, event) {
                console.log(tab, event)
            },
            resetForm() {
                !this.isPassValidate && this.$refs['_externalSystemForm'].resetFields();//清空表单
            },
            saveExternalSystem() {
                var _this = this;

                _this.$refs["_externalSystemForm"].validate((valid) => {
                    _this.isPassValidate = valid;
                    if (valid) {
                        _this.$ajax.send("omsapi/sysexternalsystem/edit", "post", _this.dataSource, (data) => {
                            _this.dataSource.ExternalSystemID === null && (_this.dataSource.ExternalSystemID = data.Result);
                            _this.$parent.syncDataSource();
                            _this.Event.$emit("reloadExternalSystemPageList");
                            _this.Utils.messageBox("保存成功.", "success");
                        });
                    } else {
                        return false;
                    }
                });
            },
            addComParam: function () {
                var _this = this;
                if (!_this.dataSource.ComExternalParamList) return;

                var newRow = { 
                    Name: null, Code: null, Value: null,
                    ExternalSystemID:_this.dataSource.ExternalSystemID,
                    ExternalParamType:'3',
                    RowEdit: true,
                };
                _this.dataSource.ComExternalParamList.unshift(newRow);
            },
            removeComParam: function (row, index) {   
                var _this = this;            
                //console.log(row);
                if(row.ExternalParamID>0&&_this.dataSource.ExternalSystemID!=null&&_this.dataSource.ExternalSystemID!=0){
                    _this.$ajax.send("omsapi/sysexternalsystem/delparam", "post", row, (data) => {
                        _this.Utils.messageBox("删除成功.", "success");
                        this.dataSource.ComExternalParamList.remove(row);
                    });
                }else{
                    this.dataSource.ComExternalParamList.remove(row);
                }
                
            },          
            addComMap: function () {
                var _this = this;
                if (!_this.dataSource.ComExternalMappingList) return;
                var newRow = { 
                    Name: null, Code: null, ExternalValue: null, LocalValue: null,
                    ExternalSystemID:_this.dataSource.ExternalSystemID,
                    ExternalParamType:'4',
                    RowEdit: true,
                };
                _this.dataSource.ComExternalMappingList.unshift(newRow);
            },
            removeComMap: function (row, index) {
                var _this = this;
                if(row.ExternalParamID>0&&_this.dataSource.ExternalSystemID!=null&&_this.dataSource.ExternalSystemID!=0){
                    _this.$ajax.send("omsapi/sysexternalsystem/delparam", "post", row, (data) => {
                        _this.Utils.messageBox("删除成功.", "success");
                        this.dataSource.ComExternalMappingList.remove(row);
                    });
                }else{
                    this.dataSource.ComExternalMappingList.remove(row);
                }
            },
            addParam: function () {
                var _this = this;
                if (!_this.dataSource.ExternalParamList) return;
                var newRow = { 
                    Name: null,Value: null,
                    ExternalSystemID:_this.dataSource.ExternalSystemID,
                    ExternalParamType:1,
                    RowEdit: true,
                };
                _this.dataSource.ExternalParamList.unshift(newRow);
            },
            removeParam: function (row, index) {
                this.dataSource.ExternalParamList.remove(row);
                var _this = this;
                if(row.ExternalParamID>0&&_this.dataSource.ExternalSystemID!=null&&_this.dataSource.ExternalSystemID!=0){
                    _this.$ajax.send("omsapi/sysexternalsystem/delparam", "post", row, (data) => {
                        _this.Utils.messageBox("删除成功.", "success");
                        this.dataSource.ExternalParamList.remove(row);
                    });
                }else{
                    this.dataSource.ExternalParamList.remove(row);
                }
            },
            addMap: function () {
                var _this = this;
                if (!_this.dataSource.ExternalMappingList) return;

                var newRow = { Name: null,Value: null ,
                    ExternalSystemID:_this.dataSource.ExternalSystemID,
                    ExternalParamType:2,
                    RowEdit: true,
                };
                _this.dataSource.ExternalMappingList.unshift(newRow);
            },
            removeMap: function (row, index) {
                this.dataSource.ExternalMappingList.remove(row);
                this.dataSource.ExternalParamList.remove(row);
                var _this = this;
                if(row.ExternalParamID>0&&_this.dataSource.ExternalSystemID!=null&&_this.dataSource.ExternalSystemID!=0){
                    _this.$ajax.send("omsapi/sysexternalsystem/delparam", "post", row, (data) => {
                        _this.Utils.messageBox("删除成功.", "success");
                        this.dataSource.ExternalMappingList.remove(row);
                    });
                }else{
                    this.dataSource.ExternalMappingList.remove(row);
                }
            },
            editParam: function (row) {
                row.RowEdit = true;
            },
            saveParam(row){
                var _this = this;
                _this.$ajax.send("omsapi/sysexternalsystem/editparam", "post", row, (data) => {
                    row.ExternalParamID=data.Result;
                    row.RowEdit = false;
                    _this.Utils.messageBox("保存成功.", "success");
                });
            },
        }
    }

</script>

<style>
</style>